<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Søk"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

  <v-data-table
    :headers="headers"
    :items="videos"
    :items-per-page="10"
    :loading="loading"
    :search="search"
    :sort-by="['createdAt']"
    :sort-desc="[true]"
  >

    <template v-if="res.errors && res.errors.length > 0" v-slot:top>
      <p>Noe gikk galt: {{res.errors[0].message}}</p>
    </template>

    <template v-slot:top>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">Slett video</v-card-title>
            <v-card-text>Er du sikker på at du vil slette denne videoen ? Alle kapitler vil også bli slettet.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">Avbryt</v-btn>
              <v-btn color="green darken-1" text @click="removeVideo">Slett</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>



    <template v-if="true" v-slot:progress>
      <v-skeleton-loader
        type="table"
        :tile="true"
      ></v-skeleton-loader>
    </template>

    <template v-slot:item.createdAt="{ item }">
      <span>{{new Date(item.createdAt).toLocaleString()}}</span>
    </template>

    <template v-slot:item.action="{ item }">
      <v-tooltip top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            class="mr-4"
            @click="editVideo(item)"
          >
            mdi-movie-edit
          </v-icon>
        </template>
        <span>Rediger videodetaljer</span>
      </v-tooltip>

      <v-tooltip top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            class="mr-4"
            @click="editChapters(item)"
          >
            mdi-file-table-box-multiple
          </v-icon>
        </template>
        <span>Rediger kapitler</span>
      </v-tooltip>

      <v-icon
        class="mr-0"
        @click="remove(item)"
      >
        mdi-delete
      </v-icon>
    </template>

  </v-data-table>

</v-card>
</template>

<!-- BRUK TABLES! -->
<script>
export default {
 props: ["videos", "loading"],
 name: 'VideoList',
 data () {
   return {
     res: {},
     dialog: false,
     video: null,
     search: ''
   }
 },
 computed: {
  // hideOnSmallScreens () { return this.$vuetify.breakpoint.xs },
  headers () {
    if (this.$vuetify.breakpoint.xs) { //
      return [
       {
         text: 'Tittel', // Lag heller en slot/template som heter info som viser fler attributter.
         value: 'title',
         sortable: false
       },
        {
          // text: 'Handlinger',
          value: 'action',
          sortable: false
        }
       ]
    } else {
      return [
       {
         text: 'Tittel',
         value: 'title'
       },
       {
         text: 'Kategori',
         value: 'category.name'
       },
       {
         text: 'Opprettet',
         value: 'createdAt'
       },
       { text: 'Handlinger', value: 'action', sortable: false }
     ]
    }
  }
 },
 methods: {
    async remove (video) { // open dialog and set video to be removed
      this.video = video
      // const index = this.videos.indexOf(video)
      this.dialog = true
    },
    removeVideo () { // get the video to be removed and close dialog
      this.$emit('remove', this.video)
      this.dialog = false
    },
    editVideo (video) {
      // console.log('this', this)
      // console.log('update', video.id)
      this.$router.push({ name: 'editVideo', params: { videoId: video.id }})
    },
    editChapters (video) {
      this.$router.push({ name: 'editChapters', params: { videoId: video.id }})
    }
 }
}
</script>

<style>

</style>
