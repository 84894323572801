<template>
<div class="scroll-list">
  <VideoList :videos="videos" :loading="loading" v-on:remove="removeVideo"/>
</div>
</template>

<script>
import VideoList from '@/components/videos/VideoList.vue'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations'

export default {
  data () {
    return {
      videos: [],
      loading: true
    }
  },
  components: {
    VideoList
  },
  created () {
    this.list()
  },
  methods: {
    async removeVideo (video) {
      // console.log('remove slett video', video.fileName)
      const index = this.videos.indexOf(video)
      // console.log('index', index)
      this.videos.splice(index, 1) // remove from

      // Delete file from S3
      try {
        console.log('fileName: ', video.fileName)
        const res = await Storage.remove(video.fileName)
        console.log('res', res)
      } catch (err) {
        console.log('feil: ', err)
        alert('Filen ble ikke slettet', err)
      }

      try {
        // delete from database
        await API.graphql(graphqlOperation(mutations.deleteVideo,
              { input: {
                id: video.id
        } }))
      } catch (e) {
        console.log('Sletting feilet', e)
        alert('Sletting feilet. Er du tilkoblet internett ?')
        this.videos.splice(index, 0, video)
      }

    },
    async list () {
      let result
      try {
        // result = await API.graphql(
        //   graphqlOperation(queries.listVideos, {
        //     limit: 400,
        //     authMode: 'AMAZON_COGNITO_USER_POOLS'
        //   })
        // )
        result = await API.graphql({
            query: queries.listVideos,
            variables: {
              limit: 400
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          })
        console.log('Videoer.vue - result videoer:\n', result)
        this.loading = result.loading
        this.videos = result.data.listVideos.items
      } catch (e) {
        console.log(e)
        this.res = e
      }
    },
    // async getVideo () {
    //   let res
    //   try {
    //     res = await API.graphql({
    //       query: queries.getVideo,
    //       variables: {
    //         id: '6PJPxkQEG6M4Ymjo4'
    //       },
    //     })
    //   } catch (err) {
    //     this.loading = false
    //     console.log('Video for chapters :', err)
    //   }
    //   console.log('get video res', res)
    // }
  }
}
</script>

<style>

</style>
